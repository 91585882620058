<template>
    <div class="report-page">
        <div class="container">
            <div class="row d-flex align-items-center mb-36">
                <div class="info-report d-flex flex-wrap gap-32 col-auto">
                    {{ !formFields.id ? $t('title.add') : $t('title.edit') }} {{ $t('title.user') }}
                </div>
                <div class="col-auto ms-auto">
                    <router-link :to="{name:'adminUser'}" class="sec-color text-decoration-none"
                    >
                        <svg
                            class="me-2"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                            />
                        </svg>
                        {{ $t('title.back') }}
                    </router-link>
                </div>
            </div>
        </div>

        <form @submit.prevent="handleSubmit" autocomplete="off">
            <div class="row mx-0 mb-4">
                <hr class="col-12 hr-basic mb-0" />
            </div>
            <div class="row col-6 mb-3 mb-lg-5 mb-xxl-60">
                <div class="col-6 col-xs-12 mb-5">
                    <!--  add class was-validated to the previous line for invalid design -->
                    <label for="name" class="fw-medium mb-2 text-grey-input">{{ $t('title.fullName') }}</label>
                    <input
                        v-model="formFields.name"
                        type="text"
                        class="form-control form-input"
                        id="name"
                        name="name"
                        required
                    />
                    <p class="text-danger" v-if="formErrors.has('name')">
                        {{ formErrors.first('name') }}
                    </p>
                </div>
                <div class="col-6 col-xs-12 mb-5">
                    <label for="email" class="fw-medium mb-2 text-grey-input">{{ $t('title.email') }}</label>
                    <input
                        v-model="formFields.email"
                        type="text"
                        class="form-control form-input"
                        id="email"
                        name="email"
                        required
                    />
                    <p class="text-danger" v-if="formErrors.has('email')">
                        {{ formErrors.first('email') }}
                    </p>
                </div>
                <div class="col-6 col-xs-12 mb-5">
                    <label for="role" class="fw-medium mb-2 text-grey-input">{{ $t('title.role') }}</label>
                    <treeselect v-model="formFields.role" class="form-input" :options="dropdowns.roles" />
                    <p class="text-danger" v-if="formErrors.has('role')">
                        {{ formErrors.first('role') }}
                    </p>
                </div>
                <div class="col-6 col-xs-12 mb-5">
                    <label for="is_active" class="fw-medium mb-2 text-grey-input">{{ $t('title.isLogin') }}</label>
                    <b-form-checkbox v-model="formFields.is_active" id="is_active" />
                </div>
                <div class="col-6 col-xs-12 mb-5">
                    <label for="password" class="fw-medium mb-2 text-grey-input">{{ $t('title.password') }}</label>
                    <input
                        v-model="formFields.password"
                        type="password"
                        class="form-control form-input"
                        id="password"
                        name="password"
                    />
                    <p class="text-danger" v-if="formErrors.has('password')">
                        {{ formErrors.first('password') }}
                    </p>
                </div>
                <div class="col-6 col-xs-12 mb-5">
                    <label for="password_confirmation" class="fw-medium mb-2 text-grey-input">
                        {{ $t('title.passwordConfirmation') }}
                    </label>
                    <input
                        v-model="formFields.password_confirmation"
                        type="password"
                        class="form-control form-input"
                        id="password_confirmation"
                        name="password_confirmation"
                    />
                    <p class="text-danger" v-if="formErrors.has('password_confirmation')">
                        {{ formErrors.first('password_confirmation') }}
                    </p>
                </div>
            </div>
            <div class="row mx-0 mb-5 mb-xxl-60">
                <hr class="col-12 hr-basic mb-0" />
            </div>
            <div
                class="row mb-5 gap-2"
            >
                <button :disabled="loader" @click="handleCancel" type="button"
                        class="btn main-btn fw-semibold col col-lg-auto mw-220">
                    <span>{{ $t('title.cancel') }}</span>
                </button>
                <button :disabled="loader" type="submit" class="btn main-btn fw-semibold col col-lg-auto mw-220">
                    <span>{{ $t('title.saveAndClose') }}</span>
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import { request } from "@/Util/Request";
import { mapGetters } from "vuex";
import Error from "@/Util/Error";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

const DEFAULT_FORM_STATE = {
    name: null,
    email: null,
    password: null,
    role: null,
    is_active: true,
    password_confirmation: null,
};

export default {
    components: { Treeselect },
    data() {
        return {
            questions: [],
            detail: null,
            operation: null,
            dropdowns: {
                roles: []
            },
            formFields: { ...DEFAULT_FORM_STATE },
            formErrors: new Error({}),
        };
    },
    mounted() {
        this.getDropdowns();
        if ( this.$route.params && this.$route.params.id ) {
            this.getDetail(this.$route.params.id);

            if ( this.$route.params.operation ) {
                this.operation = this.$route.params.operation;
            }
        }
    },
    methods: {
        addQuestion() {
            if ( this.questions.length > 10 ) {
                alert('Maximum number of question able to enter only 10');
            } else {
                this.questions.push({});
            }
        },
        async handleSubmit() {
            try {
                if ( this.formFields.id ) {
                    this.formFields._method = 'PUT';
                }

                const response = await request({
                    method: 'post',
                    url: this.formFields.id ? `/admin/users/${ this.formFields.id }` : `/admin/users`,
                    data: {
                        ...this.formFields,
                    }
                });

                if ( this.formFields.id ) {
                    this.notifyWarning();
                } else {
                    this.notifySuccess();
                }

                this.handleCancel();

            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }

                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },

        async getDetail(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/admin/users/${ id }`
                });

                const { data } = response;

                this.formFields.country_id = data.country?.id;
                this.formFields.name = data.name;
                this.formFields.email = data.email;
                this.formFields.id = data.id;
                this.formFields.role = ( _.first(data.roles) || {} ).id;
                this.formFields.is_active = !data.is_new;
                this.formFields.is_active = data.is_active;
                this.histories = _.map(data.histories, (item) => {
                    return {
                        ...item,
                        'starting_date': new Date(item.starting_date),
                        'release_date': new Date(item.release_date),
                    };
                });
                // this.diagnosis = data.diagnosis;

            } catch (error) {
                this.notifyError()
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/admin/dropdowns/roles`
                });

                const { data } = response;
                this.dropdowns.roles = data;

            } catch (error) {
                this.notifyError()
            }
        },
        handleCancel() {
            this.formFields = { ...DEFAULT_FORM_STATE };
            this.$router.push({ name: 'adminUser' });
        }
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
