<template>
    <div>
        <div class="pt-5 px-3 px-lg-0">
            <div class="row m-0">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs basic-tab p-0 d-flex justify-content-between justify-content-md-start col-md-auto" id="reportTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="completed-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#completed"
                            type="button"
                            role="tab"
                            aria-controls="completed"
                            aria-selected="true"
                            :class="{ 'active': activeTab == 'completed' }"
                            @click="setActiveTab('completed')"
                        >
                            Completed Reports
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="uncompleted-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#uncompleted"
                            type="button"
                            role="tab"
                            aria-controls="uncompleted"
                            aria-selected="false"
                            :class="{ 'active': activeTab == 'uncompleted' }"
                            @click="setActiveTab('uncompleted')"
                        >
                            Pending Reports
                        </button>
                    </li>
                </ul>
                <div class="col-auto ms-auto pe-0">
                    <button :disable="loader" @click="handleOpenClick"
                            class="btn main-btn" title="Add New">
                                <span class="d-flex justify-content-center align-items-center gap-1">
                                    {{ $t('title.createNewTest') }} <i class="bi bi-plus-lg"></i>
                               </span>
                    </button>
                </div>
            </div>
            <div class="row m-0">
                <div class="tab-content p-0" id="reportTabContent">
                    <div
                        class="tab-pane fade"
                        :class="{ 'show active': activeTab == 'completed' }"
                        id="completed"
                        role="tabpanel"
                        aria-labelledby="completed-tab"
                    >
                        <BasicTable class="d-none d-md-block" :tableData="completedTests" tableName="complete"/>
                        <div class="d-block d-md-none mt-3">
                            <Card :tableData="completedTests" tableName="complete"/>
                        </div>
                    </div>
                    <div
                        class="tab-pane fade"
                        :class="{ 'show active': activeTab == 'uncompleted' }"
                        id="uncompleted"
                        role="tabpanel"
                        aria-labelledby="uncompleted-tab"
                    >
                        <BasicTable class="d-none d-md-block" :tableData="pendingTests" tableName="pending"/>
                        <div class="d-block d-md-none mt-3">
                            <Card :tableData="pendingTests" tableName="pending"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-test-modal v-on:refresh-list="refreshList" ref="addTest" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreateTestModal from "./modal/createTestModal";
import BasicTable from '../../../components/BasicTable';
import {request} from "@/Util/Request";
import Card from "@/components/Card";

export default {
    components: {
        Card,
        CreateTestModal,
        BasicTable
    },
    data() {
        return {
            tests: [],
            pendingTests: [],
            completedTests: [],
            operation: null,
            addTestModal: false
        };
    },
    mounted() {
        this.getTests();
    },
    methods: {
        setActiveTab(tab) {
            this.$store.dispatch('setActiveTab', tab);
        },
        async getTests() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/test-lists',
                });

                const { data } = response;

                this.tests = data.lists;
                this.pendingTests = data.pending;
                this.completedTests = data.completed;

            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
        handleOpenClick() {
            this.$refs['addTest'].handleToggleModal();
        },
        refreshList() {
            this.getTests();
        }
    },
    computed: {
        ...mapGetters(['loader', 'activeTab'])
    }
};
</script>
