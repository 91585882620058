<template>
    <div class="login w-100 d-flex mt-5">
        <section class="login col-12 mx-auto align-self-md-center col-md-7 col-xl-5">
            <div class="half-design row">
                <div class="col-12 form-block p-md-4 p-3">
                    <ul class="nav nav-tabs visually-hidden">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ active: activeTab === 'email' }"
                                data-bs-toggle="tab"
                                href="#email"
                            >{{ $t('title.email') }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ active: activeTab === 'otp' }"
                                data-bs-toggle="tab"
                                href="#otp"
                            >{{ $t('title.otp') }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ active: activeTab === 'pass' }"
                                data-bs-toggle="tab"
                                href="#pass"
                            >{{ $t('title.pass') }}</a
                            >
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div
                            class="tab-pane container p-0 fade show"
                            :class="{ active: activeTab === 'email' }"
                            id="email"
                        >
                            <form action="" @submit.prevent="sentOtp">
                                <h3 class="mb-3">{{ $t('title.signUp') }}</h3>

                                <div class="input-group mb-3">
                                    <!-- is-invalid or was-validated -->
                                    <div
                                        :class="formErrors.first('email') ? `is-invalid form-floating` : `form-floating`">
                                        <input
                                            type="email"
                                            class="form-control base-input"
                                            id="floatingEmail"
                                            v-model="formFields.email"
                                            placeholder="name@example.com"
                                            @focus="removeError('email')"
                                        />
                                        <label for="floatingEmail" class="input-grey fs-14">{{
                                                $t('title.email')
                                            }}</label>

                                        <svg class="icon icon-alert-triangle" width="16" height="16">
                                            <use :href="icons + '#icon-alert-triangle'"></use>
                                        </svg>
                                        <svg class="icon icon-check" width="16" height="16">
                                            <use :href="icons + '#icon-check'"></use>
                                        </svg>
                                    </div>
                                    <div class="invalid-feedback" v-if="formErrors.get('email')">
                    <span v-for="(error, index) in formErrors.get('email')" :key="index">{{
                            error
                        }}</span>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    class="btn main-btn d-block fs-20 w-100 py-3 btnNextTab"
                                    :disabled="loader || !validate"
                                >
                                    {{ $t('title.next') }}
                                </button>

                                <div class="mb-3">
                                    <p class="title text-center"><span class="py-1 bg-white">{{ $t('title.or') }}</span>
                                    </p>
                                </div>
                                <div class="social-btn">
                                    <!--                                    <button class="btn social-btn mb-3 w-100" data-title="Apple">-->
                                    <!--                                        <svg width="24" height="24" class="icon me-2">-->
                                    <!--                                            <use :href="icons + '#icon-Apple'"></use>-->
                                    <!--                                        </svg>-->
                                    <!--                                        Continue with Apple-->
                                    <!--                                    </button>-->
                                    <!--                                    <button class="btn social-btn mb-3 w-100" data-title="Microsoft">-->
                                    <!--                                        <svg width="24" height="24" class="icon me-2">-->
                                    <!--                                            <use :href="icons + '#icon-Microsoft'"></use>-->
                                    <!--                                        </svg>-->
                                    <!--                                        Sign in with Microsoft-->
                                    <!--                                    </button>-->
                                    <!--                                    <button class="btn social-btn mb-3 w-100" data-title="Google" :disabled="true">-->
                                    <!--                    <span>-->
                                    <!--                      <svg width="24" height="24" class="icon">-->
                                    <!--                        <use :href="icons + '#icon-Google'"></use>-->
                                    <!--                      </svg>-->
                                    <!--                    </span>-->
                                    <!--                                        Sign in with Google-->
                                    <!--                                    </button>-->
                                    <p class="text-ship-grey text-center mt-37">
                                        {{ $t('title.alreadyHavingAccount') }}
                                        <router-link :to="{name:'login'}" class="text-main text-decoration-none">
                                            {{ $t('title.signIn') }}
                                        </router-link>
                                    </p>
                                </div>
                            </form>
                        </div>

                        <div
                            class="tab-pane container p-0 fade show"
                            :class="{ active: activeTab === 'otp' }"
                            id="otp"
                        >
                            <form action="" @submit.prevent="verifyOtp">
                                <h3 class="mb-3">{{ $t('title.verifyYourEmail') }}</h3>
                                <p class="fs-18 mb-3 grey-info">
                                    {{ $t('title.weSentVerificationCode') }} {{ formFields.email }}
                                </p>

                                <div
                                    class="input-group mb-3 d-flex justify-content-center gap-3 otp-input"
                                >
                                    <!-- is-invalid or was-validated -->
                                    <input
                                        v-for="(digitInput, index) in otpLength"
                                        :key="index"
                                        ref="otpInput"
                                        v-model="otpArray[index]"
                                        @keydown="handleEnter(index, $event)"
                                        @input="handleInput(index, $event)"
                                        @paste="handlePaste(index, $event)"
                                        type="text"
                                        step="1"
                                        maxlength="1"
                                        :class="[`form-control`, `default-input`, `text-code`, formErrors.first('otp')?`is-invalid`:(isValidateOTP?`is-valid`:``)]"
                                        @focus="removeError('otp')" />

                                    <div class="invalid-feedback" v-if="formErrors.get('otp')">
                                        <span v-for="(error, index) in formErrors.get('otp')" :key="index">{{
                                                error
                                            }}</span>
                                    </div>
                                </div>
                                <p class="text-ship-grey text-left mb-3">
                                    {{ $t('title.didNotGetCode') }}
                                    <a
                                        href="javascript:void(0);"
                                        class="text-main text-decoration-none"
                                        v-on:click="resendOtp"
                                    >{{ $t('title.sendAgain') }}</a
                                    >
                                </p>

                                <p class="text-ship-grey text-left mb-3">
                                    {{ $t('title.wrongEmailAddress') }}
                                    <a
                                        href="#"
                                        class="text-main text-decoration-none btnPrevTab"
                                        v-on:click="(activeTab = 'email'), (loader = false)"
                                    >Go back</a
                                    >
                                </p>
                                <button type="submit" class="btn main-btn d-block fs-20 w-100 py-3 btnNextTab">
                                    {{ $t('title.next') }}
                                </button>
                            </form>
                        </div>
                        <div
                            class="tab-pane container p-0 fade show"
                            :class="{ active: activeTab === 'pass' }"
                            id="pass"
                        >
                            <form action="" @submit.prevent="setPassword">
                                <h3 class="mb-3">{{ $t('title.choosePassword') }}</h3>

                                <div class="input-group mb-3">
                                    <!-- is-invalid or was-validated -->
                                    <div
                                        :class="
                      formErrors.first('password') ? `is-invalid form-floating` : `form-floating`
                    "
                                    >
                                        <input
                                            type="password"
                                            class="form-control base-input"
                                            id="floatingPassword"
                                            placeholder="Password"
                                            v-model="passwordFields.password"
                                            @focus="removeError('password')"
                                        />
                                        <label for="floatingPassword" class="input-grey fs-14">{{
                                                $t('title.password')
                                            }}</label>

                                        <svg class="icon icon-alert-triangle" width="16" height="16">
                                            <use :href="icons + '#icon-alert-triangle'"></use>
                                        </svg>
                                        <svg class="icon icon-check" width="16" height="16">
                                            <use :href="icons + '#icon-check'"></use>
                                        </svg>
                                        <span toggle="#floatingPassword" class="field-icon toggle-password">
                      <svg class="icon icon-Eye" width="16" height="16">
                        <use :href="icons + '#icon-Eye'"></use>
                      </svg>
                      <svg class="icon icon-Eye-off" width="16" height="16">
                        <use :href="icons + '#icon-Eye-off'"></use>
                      </svg>
                    </span>
                                    </div>
                                    <div class="invalid-feedback" v-if="formErrors.get('password')">
                    <span v-for="(error, index) in formErrors.get('password')" :key="index">{{
                            error
                        }}</span>
                                    </div>
                                </div>
                                <div class="input-group mb-3">
                                    <!-- is-invalid or was-validated -->
                                    <div
                                        :class="
                      formErrors.first('password_confirmation')
                        ? `is-invalid form-floating`
                        : `form-floating`
                    "
                                    >
                                        <input
                                            type="password"
                                            :class="
                        formErrors.first('password_confirmation')
                          ? `is-invalid form-control base-input`
                          : `form-control base-input`
                      "
                                            id="floatingPasswordConfirm"
                                            placeholder="Confirm Password"
                                            v-model="passwordFields.password_confirmation"
                                        />
                                        <label for="floatingPasswordConfirm" class="input-grey fs-14"
                                        >{{ $t('title.confirmPassword') }}</label
                                        >

                                        <svg class="icon icon-alert-triangle" width="16" height="16">
                                            <use :href="icons + '#icon-alert-triangle'"></use>
                                        </svg>
                                        <svg class="icon icon-check" width="16" height="16">
                                            <use :href="icons + '#icon-check'"></use>
                                        </svg>
                                        <span toggle="#floatingPasswordConfirm" class="field-icon toggle-password">
                      <svg class="icon icon-Eye" width="16" height="16">
                        <use :href="icons + '#icon-Eye'"></use>
                      </svg>
                      <svg class="icon icon-Eye-off" width="16" height="16">
                        <use :href="icons + '#icon-Eye-off'"></use>
                      </svg>
                    </span>
                                    </div>
                                    <div class="invalid-feedback" v-if="formErrors.get('password_confirmation')">
                    <span
                        v-for="(error, index) in formErrors.get('password_confirmation')"
                        :key="index"
                    >{{ error }}</span
                    >
                                    </div>
                                </div>
                                <button type="submit" class="btn main-btn d-block fs-20 w-100 py-3 mb-3">
                                    {{ $t('title.savePassword') }}
                                </button>

                                <div class="mb-5">
                                    <p class="title text-center"><span class="py-1 bg-white">{{ $t('title.or') }}</span>
                                    </p>
                                </div>
                                <div class="social-btn">
                                    <p class="text-ship-grey text-center">
                                        {{ $t('title.alreadyHavingAccount') }}
                                        <router-link :to="{name:'login'}"
                                                     class="text-main text-decoration-none">{{ $t('title.signIn') }}
                                        </router-link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import icons from '@/assets/icons.svg';
import Error from "@/Util/Error";
import { mapActions, mapGetters } from "vuex";
import { request } from "@/Util/Request";
import { setStorage, getAuthUser, getStorage } from "@/Util/auth";

const DEFAULT_FORM_STATE = {
    email: null,
};
const DEFAULT_FORM_PASS = {
    password: null,
    password_confirmation: null,
};

export default {
    props: {
        otpLength: {
            type: Number,
            default: 4
        }
    },
    data() {
        return {
            activeTab: 'email',
            formFields: { ...DEFAULT_FORM_STATE },
            passwordFields: { ...DEFAULT_FORM_PASS },
            formErrors: new Error({}),
            otpArray: [],
            otpCode: null,
            isValidateEmail: false,
            isValidateOTP: false,
            isValidatePassword: false,
            icons: icons,
        };
    },
    mounted() {
        this.setLoader(false);
        if ( !_.isEmpty(getAuthUser()) ) {
            this.$router.push({
                name: 'dashboard',
            });
        }

        document.querySelectorAll('.toggle-password').forEach(function (toggle) {
            toggle.addEventListener('click', function () {
                toggle.classList.toggle('eye-slash');
                const input = document.querySelector(toggle.getAttribute('toggle'));
                if ( input.getAttribute('type') === 'password' ) {
                    input.setAttribute('type', 'text');
                } else {
                    input.setAttribute('type', 'password');
                }
            });
        });

    },
    methods: {
        ...mapActions(['setLoader','setValidate']),
        async handleSubmit() {
            try {
                const response = await request({
                    method: 'POST',
                    url: `/v2/auth/login/verification`,
                    data: this.formFields,
                });

                const {
                    data
                } = response;

                setStorage('auth', JSON.stringify(data));
                await this.$store.dispatch('user', data);
                await this.$router.push({ name: 'dashboard' });
                this.notifySuccessWithMsg(this.$t('title.successLogin'));
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }

                if ( error.request && error.request.status && error.request.status === 429 ) {
                    this.formErrors = new Error({
                        phone: 'unauthentic'
                    });
                    return false;
                }
            }

        },
        async sentOtp() {
            try {
                const response = await request({
                    method: 'POST',
                    url: `/auth/register`,
                    data: this.formFields,
                });
                const {
                    data
                } = response;
                setStorage('token', data.token);
                this.removeError('otp');
                this.otpArray = [];
                if ( data.registration_stage != "set_password" ) {
                    this.notifySuccessWithMsg(this.$t('title.otpSuccessMsg'));
                    this.activeTab = 'otp';
                    setStorage('token', data.token);
                } else {
                    this.notifySuccessWithMsg(this.$t('title.alreadyRegistered'));
                    await this.$router.push({
                        name: "login"
                    });
                }

            } catch (error) {
                this.isValidateEmail = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }

                if ( error.request && error.request.status && error.request.status === 429 ) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                    return false;
                }
            }
        },
        async validatedOTP() {
            const token = getStorage('token');
            try {
                const response = await request({
                    headers: {
                        Authorization: `Bearer ${ token }`,
                    },
                    method: 'POST',
                    url: `/auth/register/check-otp`,
                    data: {
                        'otp': this.otpCode
                    },
                });
                const { data } = response;
                if ( data && data.isValidateOTP ) {
                    this.isValidateOTP = data.isValidateOTP;
                    if ( this.formErrors.$errors.otp && this.isValidateOTP ) {
                        delete this.formErrors.$errors.otp;
                    }
                }
            } catch (error) {
                this.isValidateOTP = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }

                if ( error.request && error.request.status && error.request.status === 429 ) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                    return false;
                }
            }

        },
        async verifyOtp() {
            const token = getStorage('token');
            try {
                const response = await request({
                    headers: {
                        Authorization: `Bearer ${ token }`,
                    },
                    method: 'POST',
                    url: `/auth/register/verify-otp`,
                    data: {
                        'otp': this.otpCode
                    },
                });
                const {
                    data
                } = response;
                if ( data ) {
                    this.notifySuccessWithMsg(this.$t('title.otpVerifiedMsg'));
                    this.activeTab = 'pass';
                }

            } catch (error) {
                this.isValidateOTP = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }

                if ( error.request && error.request.status && error.request.status === 429 ) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                    return false;
                }
            }

        },
        async resendOtp() {
            const token = getStorage('token');
            try {
                const response = await request({
                    headers: {
                        Authorization: `Bearer ${ token }`,
                    },
                    method: 'POST',
                    url: `/auth/register/resend-otp`,
                });
                const { data } = response;
                if ( data ) {
                    this.otpArray       =   [];
                    this.isValidateOTP  =   false;
                    this.notifySuccessWithMsg(this.$t('title.otpSentSuccessMsg'));
                }

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }

                if ( error.request && error.request.status && error.request.status === 429 ) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                    return false;
                }
            }

        },
        async setPassword() {
            const token = getStorage('token');
            try {
                const response = await request({
                    headers: {
                        Authorization: `Bearer ${ token }`,
                    },
                    method: 'POST',
                    url: `/auth/register/password`,
                    data: this.passwordFields,
                });
                const {
                    data
                } = response;
                if ( data ) {
                    this.notifySuccessWithMsg(this.$t('title.registerSuccessMsg'));
                    await this.$router.push({
                        name: "login"
                    });

                }

            } catch (error) {
                this.isValidatePassword = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }

                if ( error.request && error.request.status && error.request.status === 429 ) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                    return false;
                }
            }

        },
        async removeError(key) {
            this.formErrors.remove(key);
        },
        handleInput(index, event) {
            if ( index < this.otpLength - 1 ) {
                this.$refs.otpInput[index + 1].focus();
            }
            if ( index === this.otpLength - 1 ) {
                this.$refs.otpInput[index].blur();
            }

            let joinedValue = this.otpArray.join("");

            if ( joinedValue.length === this.otpLength ) {
                this.otpCode = joinedValue;
                if ( /^[0-9]*$/.test(this.otpCode) ) {
                    this.validatedOTP();
                }
            }
        },
        handlePasteEvent(index) {
            navigator.clipboard.readText().then((pastedText) => {
                if ( /^\d+$/.test(pastedText) ) {
                    const shavedText = pastedText.replace(/[^0-9]/g, '');
                    for (let i = 0; i < shavedText.length && index + i < this.otpLength; i++) {
                        this.otpArray[index + i] = shavedText.charAt(i);
                        if ( index + i + 1 < this.otpLength ) {
                            this.$refs.otpInput[index + i + 1].dispatchEvent(
                                new Event("input")
                            );
                        }
                    }
                    let joinedValue = this.otpArray.join("");
                    if ( joinedValue.length === this.otpLength ) {
                        this.otpCode = joinedValue;
                        if ( /^[0-9]*$/.test(this.otpCode) ) {
                            this.validatedOTP();
                        }
                    }
                } else {
                    this.otpArray = [];
                    this.$refs.otpInput[0].focus();
                }
            });
        },
        validatePassword() {
            let validation = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
            if ( ( validation.test(this.passwordFields.password) ) && ( validation.test(this.passwordFields.password_confirmation) ) && this.passwordFields.password == this.passwordFields.password_confirmation ) {
                this.isValidatePassword = true;
            } else {
                this.isValidatePassword = false;
            }
        },
        validateEmail() {
            /* eslint-disable no-useless-escape */
            if ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.formFields.email) ) {
                this.isValidateEmail = true;
                if ( this.formErrors.$errors.email && this.isValidateEmail ) {
                    delete this.formErrors.$errors.email;
                }
            } else {
                this.isValidateEmail = false;
            }
        },
        handleEnter(index, event) {
            const keypressed = event.keyCode || event.which;

            if ( (( (keypressed < 48 || keypressed > 57) && (keypressed < 96 || keypressed > 105))) && keypressed !== 8 && !event.ctrlKey && keypressed !== 86 ) {
                event.preventDefault();
            }
            if ( keypressed === 8 || keypressed === 46 ) {
                event.preventDefault();
                if ( !this.otpArray[index] && index > 0 ) {
                    this.otpArray[index - 1] = "";
                    this.$refs.otpInput[index - 1].value = "";
                    this.$refs.otpInput[Math.max(0, index - 1)].focus();
                } else {
                    this.otpArray[index] = "";
                    this.$refs.otpInput[index].value = "";
                }
                this.otpCode = null;
                this.isValidateOTP = false;
            }
        },
        handlePaste(index, event) {
            this.handlePasteEvent(index);
            this.$emit("paste", event);
        },
    },
    watch: {
        'formFields.email':function(data) {
            if(data){
                this.setValidate(true);
            }else{
                this.setValidate(false);
            }
        },
        deep: true
    },
    computed: {
        ...mapGetters(['user', 'loader','validate'])
    }
};
</script>
<style>
.otp-input .form-control.is-invalid,.otp-input .form-control.is-valid {
    background-image: none;
}
</style>
