<template>
    <div class="pt-5">
        <b-row>
            <b-col sm="8" xs="12">
                <form @submit.prevent="handleSubmit" autocomplete="off">
                    <b-card>
                        <b-card-body>
                            <div class="row">
                                <div class="col-12 col-md-6 mb-5">
                                    <!--  add class was-validated to the previous line for invalid design -->
                                    <label for="name" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.fullName')
                                        }}</label>
                                    <input
                                        v-model="formFields.name"
                                        type="text"
                                        class="form-control form-input"
                                        id="name"
                                        name="name"
                                        @focus="removeError('name')"
                                    />
                                    <p class="text-danger" v-if="formErrors.has('name')">
                                        {{ formErrors.first('name') }}
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 mb-5">
                                    <label for="email" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.email')
                                        }}</label>
                                    <input
                                        v-model="formFields.email"
                                        type="text"
                                        class="form-control form-input"
                                        id="email"
                                        name="email"
                                        @focus="removeError('email')"
                                    />
                                    <p class="text-danger" v-if="formErrors.has('email')">
                                        {{ formErrors.first('email') }}
                                    </p>
                                </div>
                            </div>
                        </b-card-body>
                        <b-card-footer>
                            <div class="btn-group">
                                <b-button type="submit" variant="primary" :disabled="isSubmitButton">
                                    <i class="fa fa-spinner fa-spin" v-if="loader"></i>
                                    {{ $t('title.submit') }}
                                </b-button>
                            </div>
                        </b-card-footer>
                    </b-card>
                </form>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { request } from "@/Util/Request";
import Error from "@/Util/Error";
import {mapGetters} from "vuex";

const FORM_DATA = {
    name: null,
    email: null,
};

export default {
    data() {
        return {
            formFields: { ...FORM_DATA },
            formErrors: new Error({}),
            isSubmitButton: false
        };
    },
    mounted() {
        this.getProfile();
    },
    computed: {
        ...mapGetters(['loader']),
    },
    methods: {
        async getProfile() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/auth/profile?cards=1',
                });

                const { data } = response;
                delete data.monthly_plan;
                delete data.card;
                delete data.wallet;

                this.formFields = data;

            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
        removeError(key) {
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        async handleSubmit() {
            try {
                this.isSubmitButton = true;
                const response = await request({
                    method: 'post',
                    url: '/auth/profile',
                    data: this.formFields
                });

                this.notifySuccess(this.$t('title.profile'));
                setTimeout(() => {
                    this.isSubmitButton = false;
                }, 3000);

            } catch (error) {
                this.isSubmitButton = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
                }

            }
        },

    }
};
</script>
