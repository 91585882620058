<template>
    <data-table v-bind="parameters"  @custom-event="handleEvent" id="myids" ref="test-table"/>
</template>

<script>
import testAction  from "@/views/user/test/partials/testAction";

export default {
    name: 'TableDefault',
    props: {
        tableData: {
            type: Array,
            required: true
        },
        tableName: {
            tableName: String,
            required: false
        }
    },
    data() {
        return {
            columns: [
                {
                    key: 'id_number',
                    title: this.$t('title.idNumber'),
                    sortable: false
                },
                {
                    key: 'total_question',
                    title: this.$t('title.numberOfQuestions'),
                    sortable: false
                },
                {
                    key: 'gender',
                    title: this.$t('title.gender'),
                    sortable: false,
                },
                {
                    key: 'dob',
                    title: this.$t('title.birthday'),
                    sortable: false,
                },
                {
                    key: 'completed_at',
                    title: this.$t('title.dateCompleted'),
                    sortable: false,
                },
                {
                    key: 'action',
                    title: this.$t('title.action'),
                    sortable: false,
                    component: testAction,
                },
            ]
        };
    },
    components: {},
    computed: {
        parameters() {
            let data = this.tableData.map((item) => ({
                ...item,
                gender: this._.capitalize(item.gender),
                dob: this.$global.dateFormat(item.dob),
                completed_at: this.$global.dateFormat(item.completed_at)
            }));
            return {
                // data: [], // pass the empty array to see message
                data: data,
                lang: 'en',
                datecMode: 'single',
                showPagination: (this.tableData.length > 10) ? true : false,
                defaultPerPage: 10,
                headerHeight: 54,
                rowHeight: 54,
                showPerPage: false,
                showEntriesInfo: false,
                showSearchFilter: false,
                showDownloadButton: false,
                tableClass: 'table table-default table-hover table-borderless',
                text: {
                    emptyTableText: `Record not found`,
                    nextButtonText: '›',
                    previousButtonText: '‹',
                },

                columns: this.columns,

            };
        },
    },

    methods: {
        handleEvent(data) {
            console.log(data);
        },
    },
    mounted() {
        if(this.tableName == 'pending') {
            const index = this.columns.findIndex(column => column.key === 'completed_at'); // Finds the index of the item
            if (index !== -1) {
                this.columns.splice(index, 1); // Removes the item at the found index
            }
        }
    }
};
</script>
