<template>
    <div>
        <user-dashboard v-if="user && user?.role !== 'admin'" />
        <admin-dashboard v-if="user && user?.role == 'admin'" />
    </div>
</template>

<script>
import UserDashboard from "./user";
import AdminDashboard from "./admin";
import { mapGetters } from "vuex";

export default {
    components: {
        UserDashboard,
        AdminDashboard
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['user'])
    }
};
</script>
