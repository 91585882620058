<template>
    <div>
        <router-link v-if="!data.completed_at" :to="{'name':'testForm',params:{id:data.id,operation:'edit'}}"
                     @click="operation = 'edit'">
            <i class="bi bi-pencil-fill" />
        </router-link>
        <router-link :to="{'name':'testForm',params:{id:data.id,operation:'detail'}}"
                     @click="operation = 'detail'" class="ms-2">
            <i class="bi bi-eye-fill" />
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'testAction',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            operation: null,
        }
    }
}
</script>
