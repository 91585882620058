<template>
    <div class="newpass w-100 d-flex pt-5">
        <section class="newpass col-12 mx-auto align-self-md-center col-md-7 col-xl-5">
            <div class="half-design row">
                <div class="col-12 form-block p-md-4 p-3">
                    <router-link :to="{name:'login'}" class="sec-color text-decoration-none col-auto mb-2 d-block"
                    >
                        <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="me-2"
                        >
                            <path
                                d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                            ></path>
                        </svg>
                        {{ $t('title.backToLogin') }}
                    </router-link
                    >
                    <form @submit.prevent="handleSubmit">
                        <h3 class="mb-4">{{ $t('title.resetPassword') }}</h3>
                        <div class="input-group mb-3">
                            <!-- is-invalid or was-validated -->
                            <div
                                :class="[
                  formErrors.first('password') ? `is-invalid form-floating` : `form-floating`,
                  this.isCorrectPassword ? `was-validated` : ``,
                ]"
                            >
                                <input
                                    type="password"
                                    class="form-control base-input"
                                    id="floatingPassword"
                                    :placeholder="$t('title.newPassword')"
                                    v-model="formFields.password"
                                    v-on:input="validatePassword"
                                    @focus="removeError('password')"
                                />

                                <label for="floatingPassword" class="input-grey fs-14">{{
                                        $t('title.newPassword')
                                    }}</label>

                                <svg class="icon icon-alert-triangle" width="16" height="16">
                                    <use :href="icons + '#icon-alert-triangle'"></use>
                                </svg>
                                <svg class="icon icon-check" width="16" height="16">
                                    <use :href="icons + '#icon-check'"></use>
                                </svg>
                                <span toggle="#floatingPassword" class="field-icon toggle-password">
                                  <svg class="icon icon-Eye" width="16" height="16">
                                    <use :href="icons + '#icon-Eye'"></use>
                                  </svg>
                                  <svg class="icon icon-Eye-off" width="16" height="16">
                                    <use :href="icons + '#icon-Eye-off'"></use>
                                  </svg>
                                </span>
                            </div>

                            <div class="invalid-feedback" v-if="formErrors.get('password')">
                <span v-for="(error, index) in formErrors.get('password')" :key="index">{{
                        error
                    }}</span>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <!-- is-invalid or was-validated -->
                            <div
                                :class="[
                  formErrors.first('password_confirmation')
                    ? `is-invalid form-floating`
                    : `form-floating`,
                  this.isCorrectPassword ? `was-validated` : ``,
                ]"
                            >
                                <input
                                    type="password"
                                    class="form-control base-input"
                                    id="floatingPasswordConfirm"
                                    placeholder="Confirm Password"
                                    v-model="formFields.password_confirmation"
                                    v-on:input="validatePassword"
                                    @focus="removeError('password_confirmation')"
                                />

                                <label for="floatingPasswordConfirm" class="input-grey fs-14">{{
                                        $t('title.confirmPassword')
                                    }}</label>

                                <svg class="icon icon-alert-triangle" width="16" height="16">
                                    <use :href="icons + '#icon-alert-triangle'"></use>
                                </svg>
                                <svg class="icon icon-check" width="16" height="16">
                                    <use :href="icons + '#icon-check'"></use>
                                </svg>
                                <span toggle="#floatingPasswordConfirm" class="field-icon toggle-password">
                  <svg class="icon icon-Eye" width="16" height="16">
                    <use :href="icons + '#icon-Eye'"></use>
                  </svg>
                  <svg class="icon icon-Eye-off" width="16" height="16">
                    <use :href="icons + '#icon-Eye-off'"></use>
                  </svg>
                </span>
                            </div>

                            <div class="invalid-feedback" v-if="formErrors.get('password_confirmation')">
                <span
                    v-for="(error, index) in formErrors.get('password_confirmation')"
                    :key="index"
                >{{ error }}</span
                >
                            </div>
                        </div>

                        <div class="d-flex gap-4">
                            <button
                                @click.prevent="
                  (formFields.password = null), (formFields.password_confirmation = null)
                "
                                class="btn main-btn d-block fs-20 h-60 px-3 col sec-btn py-3"
                            >
                                {{ $t('title.cancel') }}
                            </button>
                            <button
                                type="submit"
                                :disabled="loader"
                                class="btn main-btn d-block fs-20 h-60 px-3 col py-3"
                            >
                                {{ $t('title.setPassword') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import icons from '@/assets/icons.svg';
import Error from "@/Util/Error";
import { mapGetters } from "vuex";
import { request } from "@/Util/Request";
import { getAuthUser, hasAuthUser } from "@/Util/auth";

const DEFAULT_FORM_STATE = {
    email: null,
    token: null,
    password: null,
    password_confirmation: null,

};

export default {
    data() {
        return {
            formFields: { ...DEFAULT_FORM_STATE },
            isCorrectPassword: false,
            icons: icons,
            formErrors: new Error({})
        };
    },
    created() {
        this.formFields.email = this.$route.query.email;
        this.formFields.token = this.$route.query.token;
    },
    mounted() {
        if ( hasAuthUser() ) {
            if ( getAuthUser().role !== 'admin' ) {
                this.$router.push({ name: 'dashboard' });
            } else {
                this.$router.push({ name: 'adminDashboard' });
            }
        }
        this.tokenExpired();
        document.querySelectorAll('.toggle-password').forEach(function (toggle) {
            toggle.addEventListener('click', function () {
                toggle.classList.toggle('eye-slash');
                const input = document.querySelector(toggle.getAttribute('toggle'));
                if (input.getAttribute('type') === 'password') {
                    input.setAttribute('type', 'text');
                } else {
                    input.setAttribute('type', 'password');
                }
            });
        });
    },
    methods: {
        async tokenExpired() {
            try {
                const response = await request({
                    method: 'POST',
                    url: `/reset-password-token-expired`,
                    data: this.formFields,
                });

                const { data } = response;
                if ( data && data.is_token_expired == true ) {
                    this.notifyErrorWithMsg(this.$t('title.tokenInvalidMsg'));
                    await this.$router.push({ name: "forgotPassword" });
                }
            } catch (error) {
                this.notifyErrorWithMsg(error.data.message);
            }
        },
        removeError(key) {
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        async handleSubmit() {
            try {

                const response = await request({
                    method: 'POST',
                    url: `/reset-password`,
                    data: this.formFields,
                });

                const { data } = response;

                this.notifySuccessWithMsg(this.$t('title.passwordResetSuccessMsg'));

                await this.$router.push({
                    name: "login"
                });

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
        async cancel() {
            const response = await request({
                method: 'POST',
                url: `/user-role-by-email`,
                data: this.formFields,
            });

            const { data } = response;

            await this.$router.push({
                name: ( data && data.role == 'admin' ) ? "adminLogin" : "login"
            });
        },
        validatePassword() {
            let validation = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
            if ( ( validation.test(this.formFields.password) ) && ( validation.test(this.formFields.password_confirmation) ) && this.formFields.password == this.formFields.password_confirmation ) {
                this.isCorrectPassword = true;
            } else {
                this.isCorrectPassword = false;
            }
        }
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
