import Vue from 'vue';
import VueRouter from 'vue-router';
import { hasAuthUser, removeStorage, getAuthUser } from "@/Util/auth";

Vue.use(VueRouter);


import Login from "../views/auth/login";
import Registration from "../views/auth/registration";
import ForgotPassword from "../views/auth/forgot_password";
import NewPassword from "../views/auth/newpass";
import NewUserPassword from "../views/auth/newpass/newUserPassword";

import Profile from "../views/profile";
import Subscription from "../views/profile/partials/user/subscription";
import Wallet from "../views/profile/partials/user/wallet";

//user
import Dashboard from "../views/dashboard";
import TestUser from "../views/user/test";
import TestUserForm from "../views/user/test/form";
import GenerateTestReport from "../views/user/test/generateReport";

//admin
import adminLogin from "../views/admin/auth/login";
import AdminTestUser from "../views/admin/test";
import AdminTestUserForm from "../views/admin/test/form";
import AdminUser from "../views/admin/users";
import AdminUserForm from "../views/admin/users/form";

//error
import Error from "../views/error";

const ParentComponent = {
    render(h) {
        return h('router-view');
    }
};

const routes = [
    //auth
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login, name: 'login' },
    { path: '/register', component: Registration, name: 'registration' },
    { path: '/forgot-password', component: ForgotPassword, name: 'forgotPassword', },
    { path: '/resetpass', name: 'New Password', query: { email: '', token: '', }, component: NewPassword },
    {
        path: '/new/user/set/password',
        name: 'NewUserPassword',
        query: { email: '', token: '' },
        component: NewUserPassword
    },

    //user
    {
        path: '/',
        meta: {
            authRequired: true,
        },
        component: ParentComponent,
        children: [
            { path: 'dashboard', component: Dashboard, name: 'dashboard' },
            {
                path: 'tests',
                component: ParentComponent,
                children: [
                    { path: '', component: TestUser, name: 'test' },
                    { path: ':operation/:id?', component: TestUserForm, name: 'testForm' }
                ]
            },
            { path: 'tests/report/generate/:id', component: GenerateTestReport, name: 'testReport' },
            { path: 'profile', component: Profile, name: 'profile' },
            { path: 'wallet', component: Wallet, name: 'wallet' },
            { path: 'subscription', component: Subscription, name: 'subscription' }
        ]
    },

    //admin
    { path: '/admin/login', component: adminLogin, name: 'adminLogin' },
    { path: '/admin/dashboard', component: Dashboard, name: 'adminDashboard', authAdminRequired: true },
    {
        path: '/admin/tests',
        component: ParentComponent,
        children: [
            { path: '', component: AdminTestUser, name: 'adminTest' },
            { path: ':operation/:id?', component: AdminTestUserForm, name: 'adminTestForm' }
        ]
    },
    {
        path: '/admin/users',
        component: ParentComponent,
        children: [
            { path: '', component: AdminUser, name: 'adminUser' },
            { path: ':operation/:id?', component: AdminUserForm, name: 'adminUserForm' }
        ]
    },
    { path: '/admin/profile', component: Profile, name: 'adminProfile', authAdminRequired: true },

    //error
    {
        path: '*',
        beforeEnter: (to, from, next) => {
            next('/404');
        },
    },
    {
        path: '/404',
        name: '404',
        component: Error,
    },

];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});


router.beforeEach((to, from, next) => {
    if (
      hasAuthUser()
      && getAuthUser().role !== 'admin' &&
      to.matched.some(record => record.meta.authAdminRequired)
    ) {
        next({
            name: 'adminLogin',
        });
    }
    if (
      hasAuthUser()
      && getAuthUser().role == 'admin' &&
      to.matched.some(record => record.meta.authRequired)
    ) {
        next({
            name: '404',
        });
    }
    if (
      ( to.matched.some(record => record.meta.authRequired)
        || to.matched.some(record => record.meta.authAdminRequired) )
      && !hasAuthUser()
    ) {
        removeStorage('auth');
        next({
            name: 'login',
        });
    }


    next();
});


export default router;
