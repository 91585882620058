<template>
    <div>
        <div class="col-12 text-center py-2" v-if="isQuestionSave">
            <i class="fa fa-spin fa-spinner"></i>
        </div>
        <div class="row d-flex align-items-center mb-2">
            <div class="fw-bold col-auto">{{ title }}</div>
            <div class="col-auto d-flex gap-2 ms-auto" v-if="operation !== 'detail'">
                <button class="btn sec-btn px-32" @click="handleDiscardChanges">Discard</button>
                <button type="button" class="btn main-btn px-32" :disabled="(!formFields.field  || isQuestionSave) ? true : false" @click="handleSubmitQuestion" >Save</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div v-if="operation == 'detail'" v-html="formFields.field"></div>
                <ckeditor
                    v-if="operation !== 'detail'"
                    :editor="editor"
                    v-model="formFields.field"
                    :config="editorConfig"
                    :height="height ? height : 330"
                    class="form-control"
                ></ckeditor>
                <span v-if="formErrors.has(field)" class="text-danger">
                    {{ formErrors.get(field)[0] }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { request } from "@/Util/Request";
import Error from "@/Util/Error";
import {mapActions, mapGetters} from "vuex";

const FORM_STATE = {
    id: null,
    field: '',
    old_field: ''
};

export default {
    props: ['showMenubar', 'height', 'item', 'test_id', 'operation', 'title', 'field'],
    components: {
        ckeditor: CKEditor.component,
    },
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                placeholder: '',
                toolbar: {
                    items: [],
                },
            },
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
        };
    },
    mounted() {
        if ( !_.isEmpty(this.item) ) {
            this.formFields.id = this.item?.id;
            this.formFields.field = this.item?.[this.field];
            this.formFields.old_field = this.item?.[this.field];
        }
    },
    methods: {
        ...mapActions(['setIsQuestionSave']),
        handleDiscardChanges() {
            if ( !_.isEmpty(this.formFields.old_field) ) {
                this.formFields.field = this.formFields.old_field;
            } else {
                this.formFields.field = "";
            }
        },
        async handleSubmitQuestion() {
            try {
                this.setIsQuestionSave(true);
                this.formFields[this.field] =  this.formFields.field;
                if(this.formFields.id != null) {
                    this.formFields['_method'] = 'put';
                }
                const { field, old_field, ...submitData } = this.formFields;
                const response = await request({
                    method: 'post',
                    url: this.formFields.id ? `/test-questions/${ this.formFields.id }` : `/test-questions`,
                    data: {
                        ...submitData,
                        test_id: this.test_id
                    }
                });

                if(response.data) {
                    this.notifySuccessWithMsg(this.$t('title.questionSubmittedSuccess'));
                    this.setIsQuestionSave(false);
                    this.$emit('refresh');
                }
            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }


                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
                this.setIsQuestionSave(false);
            }
        },
    },
    watch: {
        'questionId': function (newVal, oldVal) {
            if (newVal) {
                this.formFields.id = newVal;
            }
        },
        'fieldUpdate': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.formFields.old_field = newVal;
            }
        }
    },
    computed: {
        questionId() {
            return this.item.id;
        },
        fieldUpdate() {
            return this.item?.[this.field];
        },
        ...mapGetters(['isQuestionSave'])
    }
};
</script>
