<template>
    <div class="report-page">
        <div class="container">
            <div class="row d-flex align-items-center mb-2">
                <div class="col-auto">
                    <router-link :to="{name:'adminTest'}" class="sec-color text-decoration-none"
                    >
                        <svg
                            class="me-2"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                            />
                        </svg>
                        {{ $t('title.back') }}
                    </router-link>
                </div>
            </div>
            <div class="row d-flex align-items-center mb-36">
                <div class="info-report d-flex flex-wrap gap-32 col-auto">
                    <div class="id d-flex gap-2">
                        <div class="title fw-bold text-black">ID number:</div>
                        <div class="value sec-color">{{ detail?.id_number }}</div>
                    </div>
                    <div class="gender d-flex gap-2">
                        <div class="title fw-bold text-black">Gender:</div>
                        <div class="value sec-color">{{ detail?.gender }}</div>
                    </div>
                    <div class="gender d-flex gap-2">
                        <div class="title fw-bold text-black">Birthday:</div>
                        <div class="value sec-color">{{ $global.dateFormat(detail?.dob) }}</div>
                    </div>
                </div>

                <div class="col-auto ms-auto">
                    <div class="btn btn-group gap-2">
                        <b-button variant="primary" @click="addQuestion"
                                  v-if="(questions.length < 10 && operation !== 'detail')"><i
                            class="fa fa-plus-circle"></i> Add Question
                        </b-button>
                        <a class="btn main-btn border-0" href="/tests">Generate report</a>
                    </div>
                </div>
            </div>
            <div class="row vertical-tab tab-cards m-0">
                <ul class="nav d-flex flex-column col-auto" id="reportTab" role="tablist">
                    <li class="nav-item" role="presentation" v-for="(question,index) in questions" :key="index">
                        <button
                            :class="!index ? 'nav-link active' : 'nav-link'"
                            :id="`card-tab-${index+1}`"
                            data-bs-toggle="tab"
                            :data-bs-target="`#${index+1}-card`"
                            type="button"
                            role="tab"
                            :aria-controls="`${index+1}-card`"
                            aria-selected="true"
                        >
                            Card {{ convertToRoman(index + 1) }}
                            <span>{{ index + 1 }} iteration</span>
                        </button>
                    </li>
                </ul>
                <div class="tab-content col" id="reportTabContent">
                    <div
                        class="tab-pane fade"
                        :class="{ 'show active': !index }"
                        :id="`${index+1}-card`"
                        role="tabpanel"
                        :aria-labelledby="`card-tab-${index+1}`"
                        v-for="(question, index) in questions" :key="index"
                    >
                        <h4 class="fw-bold">Card {{ convertToRoman(index + 1) }}</h4>
                        <div class="mb-4">
                            <CardGroup v-on:refresh="getDetail(detail.id)" :item="question" :test_id="detail.id"
                                       :operation="operation" title="Free Association Phase"
                                       field="free_association_phase" />
                        </div>
                        <div class="mb-4">
                            <CardGroup v-on:refresh="getDetail(detail.id)" :item="question" :test_id="detail.id"
                                       :operation="operation" title="Inquiry Phase" field="inquiry_phase" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { request } from "@/Util/Request";
import CardGroup from "@/components/CardGroup";
import { mapGetters } from "vuex";

export default {
    components: { CardGroup },
    data() {
        return {
            questions: [],
            detail: null,
            operation: null
        };
    },
    mounted() {
        if ( this.$route.params && this.$route.params.id ) {
            this.getDetail(this.$route.params.id);

            if ( this.$route.params.operation ) {
                this.operation = this.$route.params.operation;
            }
        }
    },
    methods: {
        addQuestion() {
            if ( this.questions.length > 10 ) {
                alert('Maximum number of question able to enter only 10');
            } else {
                this.questions.push({});
            }
        },
        async getDetail(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/admin/tests/${ id }`
                });

                const { data } = response;

                this.detail = _.pick(data, ['id', 'id_number', 'gender', 'dob']);

                this.questions = data.questions;

                if ( !this.questions.length ) {
                    this.questions.push({});
                }

            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
        convertToRoman(num) {
            const romanNumerals = [
                { value: 1000, numeral: 'M' },
                { value: 900, numeral: 'CM' },
                { value: 500, numeral: 'D' },
                { value: 400, numeral: 'CD' },
                { value: 100, numeral: 'C' },
                { value: 90, numeral: 'XC' },
                { value: 50, numeral: 'L' },
                { value: 40, numeral: 'XL' },
                { value: 10, numeral: 'X' },
                { value: 9, numeral: 'IX' },
                { value: 5, numeral: 'V' },
                { value: 4, numeral: 'IV' },
                { value: 1, numeral: 'I' },
            ];

            let result = '';
            for (const { value, numeral } of romanNumerals) {
                while (num >= value) {
                    result += numeral;
                    num -= value;
                }
            }
            return result;
        }
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
