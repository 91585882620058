<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h5>
                    {{ $t('title.tests') }}
                </h5>
            </div>
            <div class="card-body">
                <crud-table :columns="columns" :list-url="listUrl" ref="test-table">
                    <template #cell(dob)="{item}">
                        {{ $global.dateFormat(item.dob) }}
                    </template>
                    <template #cell(completed_at)="{item}">
                        {{ $global.dateFormat(item.completed_at) }}
                    </template>
                    <template #cell(gender)="{item}">
                        {{ _.capitalize(item.gender) }}
                    </template>
                    <template #cell(action)="{item}">
                        <router-link :to="{'name':'adminTestForm',params:{id:item.id,operation:'detail'}}"
                                     @click="operation = 'detail'" class="ms-2">
                            <i class="bi bi-eye-fill" />
                        </router-link>
                        <a href="javascript:;" @click.prevent="handleDelete(item.id)" class="ms-2">
                            <i class="bi bi-trash-fill"></i>
                        </a>
                    </template>
                </crud-table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            operation: null,
            listUrl: '/admin/tests',
            columns: [
                {
                    key: 'id_number',
                    label: this.$t('title.idNumber'),
                    sortable: false
                },
                {
                    key: 'total_question',
                    label: this.$t('title.numberOfQuestions'),
                    sortable: true
                },
                {
                    key: 'gender',
                    label: this.$t('title.gender'),
                    sortable: true
                },
                {
                    key: 'dob',
                    label: this.$t('title.birthday'),
                    sortable: true
                },
                {
                    key: 'completed_at',
                    label: this.$t('title.dateCompleted'),
                    sortable: true
                },
                {
                    key: 'action',
                    label: this.$t('title.action'),
                    sortable: false
                },
            ],
            addTestModal: false
        };
    },
    methods: {
        handleOpenClick() {
            this.$refs['addTest'].handleToggleModal();
        },
        async handleDelete(id) {
            this.$refs['test-table'].handleDeleteShow(`/admin/tests/${ id }`, 'Test');
        },
        refreshList() {
            this.$refs['test-table'].refreshTableData();
        }
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
