<template>
    <div>
        <admin-profile v-if="user?.role == 'admin'"></admin-profile>
        <user-profile v-if="user?.role != 'admin'"></user-profile>
    </div>
</template>
<script>
import AdminProfile from "./partials/admin";
import UserProfile from "./partials/user";
import { mapGetters } from "vuex";

export default {
    components: { AdminProfile, UserProfile },
    data() {
        return {};
    },
    mounted() {
    },
    computed: {
        ...mapGetters(['user'])
    }
};
</script>
