<template>
    <div>
        <div class="container mt-5">
            <div class="row text-justify-center">
                <div class="col-12 col-md-3">
                    <div class="card">
                        <div class="card-header">
                            <router-link
                                :to="{ name: 'test' }">
                                Test
                            </router-link>
                        </div>
                        <div class="card-body">
                            {{ countBox.tests }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { request } from "@/Util/Request";

export default {
    data() {
        return {
            countBox: {
                tests: 0
            }
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        async getDetail() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/dashboard`
                });

                const { data } = response;
                const { count } = data;

                this.countBox.tests = count.tests;

            } catch (error) {
                this.notifyError()
            }
        },
    }
};
</script>
